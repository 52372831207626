import Head from '@/components/Head/Head';
import config, { currentProjectName } from '@/config';
import getOgImagePath from '@/helpers/getOgImagePath';
import useSafeRouter from '@/hooks/useSafeRouter/useSafeRouter';
import getBaseUrl from '@uikit/helpers/getBaseUrl';
import { logoFileName } from './consts';
import type { PageHeadProps } from './interfaces';

const baseUrl = getBaseUrl();

const PageHead = ({
  title,
  description,
  noindex,
  canonical,
  path,
  ogDescription,
  ogImage,
  ogTitle,
  ogType = 'website',
  children,
  aggregateRating,
}: PageHeadProps): JSX.Element => {
  const {
    query: { page },
  } = useSafeRouter();

  const hasPaginationPageParam = typeof page === 'string' && +page > 0;

  const showNoIndex =
    noindex || hasPaginationPageParam || config.isPreProduction;

  const ogImageFallback = `${baseUrl}/assets/og-images/${
    logoFileName[currentProjectName] ?? 'enercity.png'
  }`;

  const currentOgImage = ogImage
    ? getOgImagePath(ogImage.path)
    : ogImageFallback;

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description || ''} />
      <meta
        name="robots"
        content={`${showNoIndex ? 'noindex' : 'index'}, follow`}
      />
      <meta property="og:type" content={ogType} />
      <meta
        property="og:description"
        content={ogDescription ?? (description || '')}
      />

      {path === '/' && (
        <meta
          name="p:domain_verify"
          content="4546a2daee8aa0e746a0e0880cb88ed7"
        />
      )}

      <meta property="og:title" content={ogTitle ?? title} />

      <meta property="og:image" content={currentOgImage} />
      <meta property="og:url" content={`${baseUrl}${path}`} />
      {(canonical || path) && (
        <link rel="canonical" href={canonical || `${baseUrl}${path}`} />
      )}

      {!!aggregateRating && (
        <script type="application/ld+json">
          {`${JSON.stringify(
            {
              '@context': 'http://schema.org',
              '@type': 'Product',
              name: 'Kundenbewertungen enercity',
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: aggregateRating.ratingValue.toFixed(2),
                reviewCount: aggregateRating.reviewCount.toString(),
              },
            },
            null,
            2
          )}`}
        </script>
      )}
      {children}
    </Head>
  );
};

export default PageHead;
