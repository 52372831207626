import { BreakPoint } from '../../../hooks/useBreakPoint/consts';
import ImageTransformerPicture from '../LegacyPicture/image-transformer/ImageTransformerPicture';
import { BreakpointAspectRatioConfigs } from '../LegacyPicture/image-transformer/types';
import styles from './BackgroundPicture.module.scss';
import { BackgroundPictureProps } from './types';

const DEFAULT_BREAKPOINT_ASPECT_RATIO_CONFIGS: BreakpointAspectRatioConfigs = [
  {
    breakpoint: BreakPoint.XSS,
    aspectRatio: '2:1',
    width: 375,
  },
  {
    breakpoint: BreakPoint.XS,
    aspectRatio: '2:1',
    width: 600,
  },
  {
    breakpoint: BreakPoint.SM,
    aspectRatio: '2:1',
    width: 800,
  },
  {
    breakpoint: BreakPoint.MD,
    aspectRatio: '2:1',
    width: 1000,
  },
  {
    breakpoint: BreakPoint.LG,
    aspectRatio: '2:1',
    width: 1300,
  },
  {
    breakpoint: BreakPoint.XL,
    aspectRatio: '2:1',
    width: 1600,
  },
];

const BackgroundPicture = ({
  asset,
  breakpointAspectRatioConfigs,
  ratioVariants,
}: BackgroundPictureProps) => {
  return (
    <ImageTransformerPicture
      path={asset.path}
      breakpointAspectRatioConfigs={
        breakpointAspectRatioConfigs ?? DEFAULT_BREAKPOINT_ASPECT_RATIO_CONFIGS
      }
      ratioVariants={ratioVariants}
      className={styles.wrapper}
      nativeLazy={false}
      crop={!!breakpointAspectRatioConfigs}
      imgProps={{
        className: styles.picture,
        alt: '',
        role: 'presentation',
      }}
    />
  );
};

export default BackgroundPicture;
